
import {computed, defineComponent, onMounted, ref} from "vue";
import {useStore} from "vuex";

import {Actions} from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

import ExclamationNotice from "@/components/my-components/notices/exclamation-notice.vue";
import {ErrorMessage, Field, Form} from "vee-validate";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

import {useToast} from "vue-toastification";

import VueFileUploadComponent from 'vue-upload-component';
import {useRouter} from "vue-router";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {reinitializeComponents} from "@/core/plugins/keenthemes";

export default defineComponent({
    name: "settings",
    components: {
        Form,
        VueFileUploadComponent,
        /*MyRate,*/
        ErrorMessage,
        Field,

        ExclamationNotice,
    },
    setup() {
        const store = useStore();
        const toast = useToast();
        const router = useRouter();

        const authToken = JwtService.getAuthToken();
        const userToken = JwtService.getToken();

        onMounted(() => {
            setCurrentPageBreadcrumbs("Налаштування", ["Profile", "Settings"]);

            setCurrentPageTitle("Налаштування");

            reinitializeComponents();
        });

        const profileDetailsValidator = Yup.object().shape({
            name: Yup.string()
                .trim('Поле не повинно містити пробілів на початку та в кінці')
                .strict(true),
                //.min(2, 'Мінімальна кількість символів 2'),
            phone: Yup.string()
                .required('Обов\'язкове поле')
                .trim('Поле не повинно містити пробілів на початку та в кінці')
                .strict(true)
                .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Перевірте правильність телефонного номера'),
            email: Yup.string().email('Перевірте формат поштової скриньки'),
        });

        const disableAccountValidator = Yup.object().shape({
            deactivate: Yup.boolean()
                .oneOf([true], 'Необхідно погодитися з вимкненням облікового запису')
                .required('Необхідно погодитися з вимкненням облікового запису'),
        });

        let in_progress = ref(false);

        const userData = computed(() => {
            let user = store.getters.currentUser;

            return user;
        });

        const upload = ref<InstanceType<typeof VueFileUploadComponent> | null>(null);
        let files = ref([]);

        const disableAccount = (values) => {
            Swal.fire({
                text: "Підтвердити тимчасове вимкнення профілю ?",
                title: "Профіль",
                icon: "question",
                buttonsStyling: false,
                confirmButtonText: "Так",
                showCancelButton: true,
                cancelButtonText: "Скасувати",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-success",
                    cancelButton: "btn fw-bold btn-light-danger",
                },
            }).then((value) => {
                if (value.isConfirmed) {
                    in_progress.value = true;

                    store.dispatch(Actions.DISABLE_PROFILE, {
                        token: JwtService.getToken(),
                        login: userData.value.user.login,
                        fn: (result) => {
                            result.success ? toast.success(result.message) : toast.error(result.message);

                            in_progress.value = false;

                            if (result.success) {
                                router.push({name: "profile-overview"});
                            }
                        },
                    });
                }
            });
        }

        const saveChanges = (values) => {

            Swal.fire({
                text: "Змінити параметри профілю ?",
                title: "Профіль",
                icon: "question",
                buttonsStyling: false,
                confirmButtonText: "Так",
                showCancelButton: true,
                cancelButtonText: "Скасувати",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-success",
                    cancelButton: "btn fw-bold btn-light-danger",
                },
            }).then((value) => {
                if (value.isConfirmed) {
                    in_progress.value = true;

                    store.dispatch(Actions.CHANGE_PROFILE_PARAMS, {
                        token: JwtService.getToken(),
                        login: userData.value.user.login,
                        name: values.name,
                        phone: values.phone,
                        email: values.email,
                        fn: (result) => {
                            result.success ? toast.success(result.message) : toast.error(result.message);

                            in_progress.value = false;
                        },
                    });
                }
            });
        }

        const removeImage = () => {
            if (in_progress.value) {
                return;
            }

            Swal.fire({
                text: "Видалити аватар ?",
                title: "Аватар",
                icon: "question",
                buttonsStyling: false,
                confirmButtonText: "Так",
                showCancelButton: true,
                cancelButtonText: "Скасувати",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-success",
                    cancelButton: "btn fw-bold btn-light-danger",
                },
            }).then((value) => {
                if (value.isConfirmed) {
                    in_progress.value = true;

                    store.dispatch(Actions.REMOVE_AVATAR, {
                        token: JwtService.getToken(),
                        login: userData.value.user.login,
                        avatar: userData.value.settings.settings.base_avatar,
                        fn: (result) => {
                            result.success ? toast.success(result.message) : toast.error(result.message);

                            files.value = [];
                            in_progress.value = false;
                        },
                    });
                }
            });
        }

        function inputFilter(newFile, oldFile, prevent: (prevent?: boolean) => boolean) {
            if (newFile && !oldFile) {
                if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
                    toast.error('Выберите файл из доступных форматов!');
                    return prevent()
                }

                if (newFile.size > (2024 * 20)) {
                    toast.error('Максимальний розмір файлу 40KB');
                    return prevent()
                }
            }

            if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                newFile.url = ''
                let URL = window.URL || window.webkitURL
                if (URL && URL.createObjectURL) {
                    newFile.url = URL.createObjectURL(newFile.file)

                    if (upload?.value && !upload.value.active) {
                        upload.value.active = true;
                    }
                }
            }
        }

        function inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                if (upload?.value && !upload.value.active) {
                    upload.value.active = true;
                }
            }
            if (newFile && oldFile) {
                in_progress.value = true;

                //file status
                if (upload.value && newFile.progress == 100 && !newFile.active) {
                    if (newFile.success) {
                        store.dispatch(Actions.VERIFY_AUTH, {
                            login: userData.value.user.login,
                            api_token: JwtService.getToken(),
                        });

                        toast.success('Аватар успішно оновлено!');
                    } else {
                        toast.error('Помилка завантаження зображення!' + newFile.error);
                    }

                    upload.value.active = false;
                    files.value = [];

                    in_progress.value = false;
                }
            }
            if (!newFile && oldFile) {
                // remove
                if (upload?.value && upload.value.active) {
                    upload.value.active = false;
                }
            }
        }

        return {
            userData,

            in_progress,

            profileDetailsValidator,
            saveChanges,
            inputFilter,
            inputFile,
            upload,
            files,
            authToken,
            userToken,
            removeImage,

            disableAccount,
            disableAccountValidator,
        };
    },
});
